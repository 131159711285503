@import "@styles/_variables.scss";
#menu {
  &.sticky {
    & > .menu-movil {
      background-color: #f9f6ef;
      // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
      & > button {
        filter: invert(100%);
      }

      & > div {
        filter: invert(100%);
      }
      & > a {
        filter: invert(100%);
      }
    }
  }

  & > .menu-movil {
    display: none;
    padding: 0 21px;
    height: 72px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    transition: all 500ms;
    &.open {
      background-color: #f9f6ef;
      & > button {
        filter: invert(100%);
      }

      & > div {
        filter: invert(100%);
      }
      & > a {
        filter: invert(100%);
      }
      & > a {
        & > img {
          filter: invert(100%);
        }
      }
    }

    @media screen and (max-width: 1263px) {
      display: flex;
    }

    & > button {
      background-color: transparent;
    }

    & > a {
      & > img {
        width: 53px;
        height: 30px;
        margin-left: 13px;
      }
    }

    & > div {
      flex: 1;
      flex-direction: row;
      justify-content: flex-end;
      padding-left: 37px;
      box-sizing: border-box;

      & > a {
        height: 31px;
        box-sizing: border-box;
        display: flex !important;
        flex-direction: row;
        font-family: GT Eesti;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        display: flex;
        align-items: center;

        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #fff;

        & > svg {
          margin-right: 9px;
        }

        &:nth-of-type(1) {
          border-right: 1px solid #fff;
          padding: 0 19px 0 0;
        }

        &:nth-of-type(2) {
          padding: 0 0 0 19px;
        }
      }
    }
  }

  & > .mobile-menu-inner {
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 72px 30px 70px;
    box-sizing: border-box;
    overflow-y: scroll;
    height: 100vh;
    width: 100%;
    max-width: 500px;
    left: -200vw;
    background-color: #f9f6ef;
    transition: left 300ms;

    &.open {
      @media screen and (max-width: 1263px) {
        left: 0;
      }
    }

    & > nav {
      & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        position: relative;
        font-family: Silk;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 40px;
        letter-spacing: -0.04em;
        color: #372f2f;
        margin-bottom: 23px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      & > a {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: stretch;
        position: relative;
        font-family: Silk;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 40px;
        letter-spacing: -0.04em;
        color: #372f2f;
        margin-bottom: 23px;
        &:last-of-type {
          margin-bottom: 0px;
        }

        &:after {
          content: "";
          background-image: url(/images/right-chevron.svg);
          width: 18px;
          height: 18px;
          position: absolute;
          right: 0;
          margin-top: -3;
        }
      }
    }

    & > .row {
      margin-top: 30px;
      flex-direction: row;
      justify-content: space-between;

      & > .ab-link {
        &:nth-of-type(2) {
          color: #372f2f !important;
          border-bottom-color: transparent;
        }
        &:nth-of-type(3) {
          color: #372f2f !important;
          border-bottom-color: transparent;
        }
      }
    }

    & > .language {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      background-color: #372f2f;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      padding-left: 15px;
      display: none;

      &.open {
        display: flex;
        z-index: 3;
      }
      & > span {
        margin-right: 14px;
        color: #fff;
        flex: 1;
      }
      & > a {
        padding: 0 30px;
        font-family: GT Eesti;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        /* identical to box height, or 160% */

        display: flex;
        align-items: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;

        /* LIGHT_BROWN_ABADÍA */

        color: #fff;
        &.selected {
          color: #8c8181;
        }
      }
    }
  }
}
