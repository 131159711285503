@import '@styles/_variables.scss';

footer {
  background-image: url("/images/FooterBg.png");
  background-size: cover;
  background-position: bottom center;
  padding: 83px 0 45px;
  align-items: center;

  @media screen and (max-width: $tablet-breakpoint) {
    padding: 93px 0 93px;

    background-color: #372f2f;
  }

  &>.footer-logo {
    width: 188px;

    @media screen and (max-width: $tablet-breakpoint) {
      display: none;
    }
  }

  &>.footer-logo-mobile {
    display: none;

    @media screen and (max-width: $tablet-breakpoint) {
      display: flex;
      width: 144px;
      height: auto;
      object-fit: contain;
    }

    &>img {
      object-fit: contain;
    }
  }

  &>nav {
    flex-direction: row;
    margin-top: 80px;

    @media screen and (max-width: $tablet-breakpoint) {
      flex-direction: column;
      margin-top: 69px;
    }

    &>a {
      font-family: GT Eesti;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #f9f6ef !important;

      @media screen and (max-width: $tablet-breakpoint) {
        text-align: center;
      }

      &+a {
        margin-left: 31px;

        @media screen and (max-width: $tablet-breakpoint) {
          margin-left: initial;
          margin-top: 37px;
        }
      }
    }
  }

  &>.social {
    flex-direction: row;
    margin-top: 51px;

    @media screen and (max-width: $tablet-breakpoint) {
      margin-top: 71px;
    }

    &>a {
      &+a {
        margin-left: 22px;
      }
    }
  }

  &>.logos {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1009px;
    margin-top: 116px;

    @media screen and (max-width: $tablet-breakpoint) {
      justify-content: space-around;
      margin-top: 54px;
    }

    &>a {
      @media screen and (max-width: $tablet-breakpoint) {
        width: 50%;
        display: flex;
        align-items: center;
        margin-top: 26px;
      }

      &>img {
        width: 100%;
        max-width: 173px;
        object-fit: cover;

        @media screen and (max-width: $tablet-breakpoint) {
          max-width: 144px;
        }
      }

      &+a {
        margin-left: 36px;

        @media screen and (max-width: $tablet-breakpoint) {
          margin-left: initial;
        }
      }
    }
  }

  .addres {
    color: #fff !important;
    margin-top: 258px !important;
    display: flex;
    align-items: baseline;
    gap: 1rem;

    @media screen and (max-width: $tablet-breakpoint) {
      margin-top: 70px !important;
      align-items: center;
    }
  }

  &>span {
    color: #fff;
    margin-top: 258px;

    @media screen and (max-width: $tablet-breakpoint) {
      margin-top: 70px;
    }
  }

  &>.row {
    flex-direction: row;
    color: #fff;
    margin-top: 31px;

    @media screen and (max-width: $tablet-breakpoint) {
      flex-direction: column;
    }

    &>a {
      @media screen and (max-width: $tablet-breakpoint) {
        display: flex;
        align-self: center;
      }

      &+a {
        margin-left: 36px;

        @media screen and (max-width: $tablet-breakpoint) {
          margin-left: initial;
          margin-top: 16px;
        }

        span {
          @media screen and (max-width: $tablet-breakpoint) {
            text-align: center;
          }
        }
      }
    }
  }
}