$mobile-breakpoint: 500px;
$tablet-breakpoint: 850px;
$gold: #a79669;

%ab-form {
  background-color: #f9f6ef;
  flex-direction: row;
  padding: 82px Max(113px, calc((100% - 1440px) / 2 + 113px));

  &.white {
    background-color: #fff;
  }

  @media screen and (max-width: $tablet-breakpoint) {
    flex-direction: column;
    padding: 61px 30px 50px;
  }

  &>.left {
    flex: 1;

    &>span {
      margin-bottom: 14px;
    }

    &>h2 {
      width: 100%;
      max-width: 416px;
    }
  }

  &>.right {
    flex: 1;

    @media screen and (max-width: $tablet-breakpoint) {
      margin-top: 50px;
    }

    &>form {
      @extend %form;
    }
  }
}

%form {
  &>.ab-input {
    margin-bottom: 48px;
  }

  &>.row {
    flex-direction: row;
    margin-bottom: 48px;

    @media screen and (max-width: $tablet-breakpoint) {
      flex-direction: column;
    }

    &>.ab-input {
      flex: 1;

      &+.ab-input {
        margin-left: 16px;

        @media screen and (max-width: $tablet-breakpoint) {
          margin-left: initial;
          margin-top: 48px;
        }
      }

      &+select {
        margin-left: 16px;

        @media screen and (max-width: $tablet-breakpoint) {
          margin-left: initial;
          margin-top: 48px;
        }
      }

      &.container {
        &.date {
          &>select {
            margin-top: 24px;
            height: 53px;
          }
        }
      }
    }

    &>select {
      flex: 1;
      padding-right: 0;

      &.error {
        color: red;
        border-bottom: 1px solid red;
        margin-bottom: 15px;
      }

      &+.ab-input {
        margin-left: 16px;
        flex: 1;

        @media screen and (max-width: $tablet-breakpoint) {
          margin-left: initial;
          margin-top: 48px;
        }
      }
    }

    &>.ab-input-quantity {
      flex: 1;

      &+.ab-input-quantity {
        margin-left: 16px;

        @media screen and (max-width: $tablet-breakpoint) {
          margin-left: initial;
          margin-top: 48px;
        }
      }
    }
  }

  &>textarea {
    font-family: GT Eesti;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    height: 193px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(32, 32, 32, 0.2);
    margin-bottom: 48px;
    outline: none;
    background-color: transparent;

    &.acompanantes {
      height: 100px;
    }

    &.error {
      border-bottom: 1px solid red;
      margin-bottom: 15px;
    }
  }

  &>select {
    margin-bottom: 48px;

    &.error {
      color: red;
      border-bottom: 1px solid red;
      margin-bottom: 15px;
    }
  }

  &>span {
    margin-bottom: 36px;
  }

  // & > button {
  //   margin-top: 22px;
  //   height: 70px;
  //   max-width: 360px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   background: #a79669;
  //   font-family: GT Eesti;
  //   font-style: normal;
  //   font-weight: normal;
  //   font-size: 10px;
  //   line-height: 16px;
  //   /* identical to box height, or 160% */

  //   text-align: center;
  //   letter-spacing: 0.2em;
  //   text-transform: uppercase;

  //   /* BEIGE_ABADÍA */

  //   color: #f9f6ef;
  // }
}

%button {
  margin: 24px 0;
  background: #a79669;
  width: 100%;
  max-width: 277px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: GT Eesti;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */

  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  /* BEIGE_ABADÍA */

  color: #f9f6ef;
  transition: background 500ms;

  &:disabled {
    background: #ccc;
  }
}