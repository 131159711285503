@import '@styles/_variables.scss';
menu {
  width: 100%;
  transition: background-color 500ms;
  position: fixed;
  z-index: 6;
  top: 0;

  &:hover {
    @media screen and (max-width: 1263px) {
      background-color: transparent;
    }

    & > .upper {
      background-color: #f9f6ef;
      z-index: 3;

      & > div {
        & > a {
          color: #372f2f;

          & > svg,
          & > img {
            filter: invert(100%);
          }
        }

        & > div {
          & > a {
            color: #372f2f;

            & > svg,
            & > img {
              filter: invert(100%);
            }

            &:nth-of-type(5) {
              border-left: 1px solid rgba(55, 47, 47, 0.2);
            }

            &:nth-of-type(6) {
              border-left: 1px solid rgba(55, 47, 47, 0.2);
            }
          }

          & > div {
            color: #372f2f;
            border-left: 1px solid rgba(55, 47, 47, 0.2);

            & > a {
              color: #372f2f;
            }
          }
        }
      }
    }

    & > .lower {
      background-color: #f9f6ef;
      border-top: 1px solid rgba(55, 47, 47, 0.2);
      border-bottom: 1px solid rgba(55, 47, 47, 0.2);
      z-index: 3;

      & > nav {
        & > a {
          color: #372f2f;
          padding-bottom: 2px;

          &:nth-of-type(1) {
            &:after {
              background-image: url(/images/menu_logo_hover.png);
              background-size: 253px 48px;

              display: inline-block;
              width: 253px;
              height: 48px;
              content: "";
            }
          }
        }

        & > div {
          color: #372f2f;
          padding-top: 2px;
        }
      }
    }

    & > .menu-movil {
      & > a {
        color: #372f2f;

        & > svg {
          filter: invert(100%);
        }
      }
    }
  }

  &.dark {
    & > .upper {
      & > div {
        & > a {
          color: #372f2f;

          & > svg,
          & > img {
            filter: invert(100%);
          }
        }

        & > div {
          & > a {
            color: #372f2f;

            & > svg,
            & > img {
              filter: invert(100%);
            }

            &:nth-of-type(5) {
              border-left: 1px solid rgba(55, 47, 47, 0.2);
            }

            &:nth-of-type(6) {
              border-left: 1px solid rgba(55, 47, 47, 0.2);
            }
          }

          & > div {
            color: #372f2f;
            border-left: 1px solid rgba(55, 47, 47, 0.2);

            & > a {
              color: #372f2f;
            }
          }
        }
      }
    }

    & > .lower {
      border-top: 1px solid rgba(55, 47, 47, 0.2);
      border-bottom: 1px solid rgba(55, 47, 47, 0.2);

      & > nav {
        & > a {
          padding-bottom: 2px;

          &:nth-of-type(1) {
            &:after {
              background-image: url(/images/menu_logo_hover.png);
              background-size: 253px 48px;

              display: inline-block;
              width: 253px;
              height: 48px;
              content: "";
            }
          }
        }

        & > div {
          color: #372f2f;
          padding-top: 2px;
        }
      }
    }

    & > .menu-movil {
      background-color: #f9f6ef;

      & > button {
        filter: invert(100%);
      }

      & > div {
        filter: invert(100%);
      }
    }
  }

  &.sticky {
    z-index: 10;

    & > .upper {
      background-color: #f9f6ef;
      z-index: 3;

      & > div {
        & > a {
          color: #372f2f;

          & > svg,
          & > img {
            filter: invert(100%);
          }
        }

        & > div {
          & > a {
            color: #372f2f;

            & > svg,
            & > img {
              filter: invert(100%);
            }

            &:nth-of-type(5) {
              border-left: 1px solid rgba(55, 47, 47, 0.2);
            }

            &:nth-of-type(6) {
              border-left: 1px solid rgba(55, 47, 47, 0.2);
            }
          }

          & > div {
            color: #372f2f;
            border-left: 1px solid rgba(55, 47, 47, 0.2);

            & > a {
              color: #372f2f;
            }
          }
        }
      }
    }

    & > .lower {
      background-color: #f9f6ef;
      border-top: 1px solid rgba(55, 47, 47, 0.2);

      border-bottom: none;
      border-bottom: 1px solid rgba(55, 47, 47, 0.2);

      // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
      & > nav {
        height: 50px;

        & > div {
          color: #372f2f;
          margin-top: 0;
          margin-bottom: 0;
          min-height: 50px;

          & > .submenu {
            top: 51px;
          }
        }

        & > a {
          &:nth-of-type(1) {
            &:after {
              background-image: url(/images/menu_logo_hover.png);

              background-size: auto 40px;
              background-repeat: no-repeat;
              display: inline-block;
              width: 253px;
              height: 40px;
              object-fit: contain;
              content: "";
            }
          }
        }
      }
    }

    & > .quick-links {
      top: 90px;
      position: absolute;
      opacity: 1;

      & > div {
        cursor: pointer;
        user-select: none;
      }
    }

    & > .menu-movil {
      & > a {
        color: #372f2f;

        & > svg,
        & > img {
          filter: invert(100%);
        }
      }
    }
  }

  & > .lower {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    align-items: center;
    transition: all 300ms;

    @media screen and (max-width: 1263px) {
      display: none;
    }

    & > nav {
      width: 100%;
      max-width: 1215px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      & > div {
        font-family: GT Eesti;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #fff;
        min-height: 70px;
        white-space: nowrap;
        padding-left: 25px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: row;
        cursor: pointer;
        padding-top: 2px;

        &:nth-of-type(1) {
          margin-left: -29px;
        }

        &:after {
          content: "•";
          color: transparent;
          font-size: 18px;
          margin-left: 5px;
          margin-top: 2px;
        }

        &.selected {
          color: #a79669;

          &:after {
            content: "•";
            font-size: 18px;
            margin-left: 5px;
            margin-top: 2px;
            color: #a79669;
          }
        }
      }

      & > a {
        font-family: GT Eesti;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #fff;
        min-height: 70px;
        white-space: nowrap;
        padding-left: 29px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-bottom: 2px;

        &:nth-of-type(1) {
          flex: 1;
          padding-left: 0;

          &:after {
            background-image: url(/images/menu_logo.png);
            background-size: 253px 48px;
            display: inline-block;
            width: 253px;
            height: 48px;
            content: "";
          }
        }

        &:nth-of-type(2) {
          margin-left: -29px;
        }

        &:after {
          content: "•";
          color: transparent;
          font-size: 18px;
          margin-left: 5px;
          margin-top: 2px;
        }

        &.selected {
          color: #a79669;

          &:after {
            content: "•";
            font-size: 18px;
            margin-left: 5px;
            margin-top: 2px;
            color: #a79669;
          }
        }
      }
    }
  }

  & > .upper {
    transition: background-color 300ms;
  }

  & > .quick-links {
    position: relative;
    transition: all 500ms;
    height: 31px;
    top: 0;
    opacity: 0;
    background-color: #a79669;
    align-self: flex-end;
    margin-right: Max(113px, calc((100% - 1440px) / 2 + 113px));
    flex-direction: row;
    overflow: hidden;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
    z-index: -1;

    @media screen and (max-width: 1263px) {
      display: none;
    }

    & > div {
      &:nth-of-type(1) {
        border-right: 1px solid #fff;
      }
    }

    & > a,
    & > div {
      display: flex;
      flex-direction: row;
      font-family: GT Eesti;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      color: #fff;
      display: flex;
      align-items: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      padding: 0 19px;

      & > svg {
        margin-right: 9px;
      }
    }
  }
}
