@import "@styles/_variables.scss";
.upper {
  height: 40px;
  background-color: transparent;
  transition: height 300ms;

  @media screen and (max-width: 1263px) {
    display: none;
  }

  & > .inner {
    width: 100%;
    max-width: 1215px;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: space-between;

    & > div {
      flex-direction: row;
      align-items: center;

      & > a {
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: GT Eesti;
        letter-spacing: 0.08em;
        color: #ffffff;
        font-size: 11px;
        line-height: 16px;

        & > svg,
        & > img {
          margin-right: 8px;
        }

        &:nth-of-type(5) {
          border-left: 1px solid rgba(255, 255, 255, 0.2);
          padding-left: 33px;
        }

        &:nth-of-type(6) {
          border-left: 1px solid rgba(255, 255, 255, 0.2);
          padding-left: 22px;
          padding-right: 22px;
        }

        & > span {
          background-color: $gold;
          color: white;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
          margin-left: -15px;
        }

        & + a {
          margin-left: 54px;
        }
      }

      & > div {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        flex-direction: row;
        align-items: center;
        color: #ffffff;
        font-size: 17px;
        height: 40px;

        & > a {
          font-family: GT Eesti;
          letter-spacing: 0.08em;
          color: #ffffff;
          font-size: 11px;
          line-height: 16px;

          &:nth-of-type(1),
          &:nth-of-type(3) {
            margin-left: 24px;
            margin-right: 15px;
          }

          &.selected {
            opacity: 0.4;
          }

          &:nth-of-type(2) {
            margin-left: 15px;
            margin-right: 15px;
          }

          &:nth-of-type(4) {
            margin-left: 15px;
          }
        }
      }
    }
  }
}
