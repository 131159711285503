@import '@styles/_variables.scss';
.ab-reservar {
  height: 120px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  // margin-top: -108px;
  padding: 9px Max(113px, calc((100% - 1440px) / 2 + 113px));
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;

  background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, rgba(18, 18, 18, 0.78) 92.62%);
  @media screen and (max-width: 1000px) {
    background: none;
    z-index: 5 !important;
    transform: none !important;
    height: 80px;
  }
  & > .submit {
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20px;
    @media screen and (max-width: 1000px) {
      display: none;
    }
    & > span {
      font-family: GT Eesti;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #a79669;
      margin-right: 20px;
    }
  }

  & > .field {
    width: 100%;
    height: 70px;
    max-width: 167px;
    padding: 0 20px 0 27px;
    position: relative;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    position: relative;
    justify-content: center;
    @media screen and (max-width: 1000px) {
      display: none;
    }
    // &:after {
    //   content: url(/images/caret.svg);
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   pointer-events: none;
    // }
    & > label {
      font-family: GT Eesti;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #ffffff;
      flex-direction: row;
      width: 100%;
    }

    & > span {
      margin-top: 5px;
      font-family: Silk;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #ffffff;
    }

    & > .cantidad {
      margin-top: 5px;
      font-family: Silk;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: #ffffff;
      position: relative;

      & > .picker {
        position: absolute;
        bottom: 110%;
        background: #f9f6ef;
        flex-direction: row;
        align-items: center;
        padding: 24px 29px;
        z-index: 10;

        & > span {
          font-size: 12px;
          color: #372f2f;
          white-space: nowrap;
          margin-right: 34px;
        }
      }
    }
  }

  & > .ab-cta {
    display: none;
    @media screen and (max-width: 1000px) {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100% !important;
    }
  }
}
