@import '@styles/_variables.scss';
.ab-quantity-picker {
  flex-direction: row;
  align-items: center;

  & > button {
    background-color: #e3decf;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > span {
    font-family: GT Eesti;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #372f2f;
    margin: 0 6px;
  }
}
