@import "@styles/_variables.scss";
.submenu {
  padding: 167px 0 90px;
  position: fixed;
  top: 112px;
  flex-direction: row;
  transition: all 500ms;
  background-color: #f9f6ef;
  width: 100%;
  z-index: 2;
  left: 0;
  top: -1000px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(55, 47, 47, 0.2);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

  &.sticky {
    padding-top: 147px;
  }

  &.open {
    top: 0px;
  }

  &.hotel {
    justify-content: center;
    & > div {
      flex-direction: row;
      width: 100%;
      max-width: 1215px;
      & > div {
        &.left {
          flex-direction: row;
          flex-wrap: wrap;
          & > a {
            font-family: Silk;
            font-style: normal;
            font-weight: 300;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.04em;
            color: #372f2f;
            text-transform: initial;
            width: 33%;
            cursor: pointer;
          }

          & > div {
            width: 33%;
            margin-bottom: 21px;
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
              margin-top: 0;
            }

            & > a {
              font-family: Silk;
              font-style: normal;
              font-weight: 300;
              font-size: 32px;
              line-height: 40px;
              letter-spacing: -0.04em;
              color: #372f2f;
              text-transform: initial;
              cursor: pointer;
            }

            & > p {
              margin-top: 13px;
              width: 100%;
              max-width: 300px;
              font-family: GT Eesti;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.01em;
              color: #8c8181;
              text-transform: initial;
              white-space: pre-wrap;
            }
          }
        }
        &.right {
          flex-direction: row;
          position: relative;
          & > a {
            & > .image {
              height: 210px;
              width: 250px;
              margin-bottom: 21px;
              position: relative;
            }
  
            & > span {
              font-family: GT Eesti;
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              line-height: 16px;
              /* or 160% */
  
              letter-spacing: 0.2em;
              text-transform: uppercase;
  
              /* BROWN_ABADÍA */
  
              color: #372f2f;
            }
  
            & > p {
              margin-top: 10px;
              max-width: 277px;
              font-family: GT Eesti;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 16px;
              white-space: pre-wrap;
              letter-spacing: 0.05em;
              color: #8c8181 !important;
              text-transform: initial;
            }
  
            & + a {
              margin-left: 44px;
            }
          }
        }
      }
    }
  }

  &.gastronomia {
    justify-content: center;
    & > div {
      width: 100%;
      max-width: 1215px;
      & > div {
        flex: 1;
        &.left {
          justify-content: space-between;
          flex-direction: row;
          flex-wrap: wrap;
          & > a {
            width: 25%;
            margin-bottom: 31px;
            box-sizing: border-box;

            & > span {
              font-family: Silk;
              font-style: normal;
              font-weight: 300;
              font-size: 32px;
              line-height: 40px;
              letter-spacing: -0.04em;
              color: #372f2f;
              text-transform: initial;
              cursor: pointer;
            }

            & > p {
              margin-top: 2px;
              width: 100%;
              max-width: 300px;
              font-family: GT Eesti;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.01em;
              color: #8c8181;
              text-transform: initial;
              white-space: pre-wrap;
            }

            & > .image {
              width: 100%;
              max-width: 277px;
              margin-top: 26px;
              height: 207px;
              position: relative;
            }

            & + a {
              padding-left: 10px;
            }
          }
        }
      }
    }
  }

  &.experiencias {
    justify-content: center;
    & > div {
      flex-direction: row;
      width: 100%;
      max-width: 1215px;

      & > .left {
        flex: 1;

        & > span {
          font-family: GT Eesti;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */
          text-transform: initial;
          letter-spacing: 0.05em;

          /* LIGHT_BROWN_ABADÍA */

          color: #8c8181;
        }

        & > div {
          // display: initial;
          // column-count: 2;
          flex-wrap: wrap;
          max-height: 210px;
          height: 100%;
          margin-top: 30px;

          & > a {
            // display: inline-block;
            // width: 100%;

            &:not(.ab-link) {
              font-family: Silk;
              font-style: normal;
              font-weight: 300;
              font-size: 24px;
              line-height: 32px;
              /* identical to box height, or 133% */

              letter-spacing: -0.04em;
              text-transform: initial;
              /* BROWN_ABADÍA */

              color: #372f2f;
              margin-bottom: 10px;
            }

            &.ab-link {
              margin-top: 21px;
            }
          }
        }
      }
      & > .right {
        flex-direction: row;

        & > a {
          & > .image {
            height: 210px;
            width: 277px;
            margin-bottom: 21px;
            position: relative;
          }

          & > span {
            font-family: GT Eesti;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            /* or 160% */

            letter-spacing: 0.2em;
            text-transform: uppercase;

            /* BROWN_ABADÍA */

            color: #372f2f;
          }

          & > p {
            margin-top: 10px;
            max-width: 277px;
            font-family: GT Eesti;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            white-space: pre-wrap;
            letter-spacing: 0.05em;
            color: #8c8181 !important;
            text-transform: initial;
          }

          & + a {
            margin-left: 44px;
          }
        }
      }
    }
  }

  &.spa {
    justify-content: center;
    & > div {
      flex-direction: row;
      width: 100%;
      max-width: 1215px;

      // & > div {
      //   &:nth-of-type(2) {
      //     margin-left: 140px;

      //     & > span {
      //       margin-left: 25px;
      //     }

      //     & > div {
      //       & > a {
      //         margin-left: 25px;
      //       }
      //     }
      //   }
      //   & > span {
      //     font-family: GT Eesti;
      //     font-style: normal;
      //     font-weight: normal;
      //     font-size: 12px;
      //     line-height: 16px;
      //     /* identical to box height, or 133% */
      //     text-transform: initial;
      //     letter-spacing: 0.05em;

      //     /* LIGHT_BROWN_ABADÍA */

      //     color: #8c8181;
      //   }

      //   & > div {
      //     // display: initial;
      //     // column-count: 2;
      //     flex-wrap: wrap;
      //     max-height: 275px;
      //     height: 100%;
      //     margin-top: 30px;

      //     & > a {
      //       &:not(.ab-link) {
      //         font-family: Silk;
      //         font-style: normal;
      //         font-weight: 300;
      //         font-size: 24px;
      //         line-height: 32px;
      //         /* identical to box height, or 133% */

      //         letter-spacing: -0.04em;
      //         text-transform: initial;
      //         /* BROWN_ABADÍA */

      //         color: #372f2f;
      //         margin-bottom: 10px;
      //       }

      //       &.ab-link {
      //         margin-top: 21px;
      //       }
      //     }
      //   }
      // }


      & > .left {
        flex: 1;

        & > span {
          font-family: GT Eesti;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */
          text-transform: initial;
          letter-spacing: 0.05em;

          /* LIGHT_BROWN_ABADÍA */

          color: #8c8181;
        }

        & > div {
          // display: initial;
          // column-count: 2;
          flex-wrap: wrap;
          max-height: 217px;
          height: 100%;
          margin-top: 30px;


          & > a {
            // display: inline-block;
            // width: 100%;

            &:not(.ab-link) {
              font-family: Silk;
              font-style: normal;
              font-weight: 300;
              font-size: 24px;
              line-height: 32px;
              /* identical to box height, or 133% */

              letter-spacing: -0.04em;
              text-transform: initial;
              /* BROWN_ABADÍA */

              color: #372f2f;
              margin-bottom: 10px;
            }

            &.ab-link {
              margin-top: 21px;
            }
          }
        }
      }


      & > .right {
        flex-direction: row;

        & > a {
          & > .image {
            height: 210px;
            width: 277px;
            margin-bottom: 21px;
            position: relative;
          }

          & > span {
            font-family: GT Eesti;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            /* or 160% */

            letter-spacing: 0.2em;
            text-transform: uppercase;

            /* BROWN_ABADÍA */

            color: #372f2f;
          }

          & > p {
            margin-top: 10px;
            max-width: 277px;
            font-family: GT Eesti;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            white-space: pre-wrap;
            letter-spacing: 0.05em;
            color: #8c8181 !important;
            text-transform: initial;
          }

          & + a {
            margin-left: 44px;
          }
        }
      }
    }
  }

  &.bodega {
    justify-content: center;
    & > div {
      flex-direction: row;
      width: 100%;
      max-width: 1215px;

      & > .left {
        & > span {
          font-family: GT Eesti;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */
          text-transform: initial;
          letter-spacing: 0.05em;

          /* LIGHT_BROWN_ABADÍA */

          color: #8c8181;
        }

        & > div {
          // display: initial;
          // column-count: 2;
          flex-wrap: wrap;
          height: 100%;
          margin-top: 30px;

          & > a {
            // display: inline-block;
            // width: 100%;

            &:not(.ab-link) {
              font-family: Silk;
              font-style: normal;
              font-weight: 300;
              font-size: 24px;
              line-height: 32px;
              /* identical to box height, or 133% */

              letter-spacing: -0.04em;
              text-transform: initial;
              /* BROWN_ABADÍA */

              color: #372f2f;
              margin-bottom: 10px;
            }

            &.ab-link {
              margin-top: 21px;
            }
          }
        }

        & + .left {
          margin-left: 75px;
        }
      }
      & > .right {
        flex-direction: row;
        justify-content: flex-end;
        flex: 1;
        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          & > a {
            &:nth-of-type(1) {
              text-align: center;
            }
            &:nth-of-type(2) {
              font-family: GT Eesti;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 16px;
              text-transform: initial;
              letter-spacing: 0.05em;
              text-align: center;
              margin-left: 0;

              margin-top: 10px;
              color: #a79669 !important;
              padding: 0 5px 10px;
              border-bottom: 1px solid #a79669;
              width: fit-content;
              cursor: pointer;
            }

            & > .image {
              width: 222px;
              height: 222px;
              margin-bottom: 21px;
              position: relative;
            }

            & > span {
              font-family: Silk;
              font-style: normal;
              font-weight: 300;
              font-size: 20px;
              line-height: 24px;
              /* or 120% */

              letter-spacing: -0.02em;

              /* BROWN_ABADÍA */

              color: #372f2f;
            }

            & > p {
              margin-top: 10px;
              max-width: 277px;
              font-family: GT Eesti;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 16px;
              white-space: pre-wrap;
              letter-spacing: 0.05em;
              color: #8c8181 !important;
              text-transform: initial;
            }

            & + a {
              margin-left: 44px;
            }
          }
        }
      }
    }
  }
}
