@import '@styles/_variables.scss';
.ab-input-quantity {
  outline: none;
  font-family: GT Eesti;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  height: 30px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.2);
  background-color: transparent;
  padding-bottom: 22px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.error {
    border-bottom-color: red;
  }

  &.no-border {
    border-bottom: none;

  }
}