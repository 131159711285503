@import '@styles/_variables.scss';
.ab-cta {
  background-color: #a79669 !important;
  position: relative;
  height: 44px !important;
  width: 360px !important;
  padding: 0 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  font-family: GT Eesti;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff !important;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2em;

  &:disabled,
  &.disabled {
    background-color: #ccc !important;
  }

  &.hero-cta {
    position: fixed;
    z-index: 2;
    width: 244px;
    height: 44px;
    bottom: 28px;
    right: 34px;
    background-color: #a79669;
    color: #fff;
    transition: all 500ms;
    box-sizing: border-box;

    @media screen and (max-width: $tablet-breakpoint) {
      bottom: 0;
      left: 0;
      width: 100% !important;
      background-color: #a79669;
    }
  }

  &.row {
    flex-direction: row;
    width: fit-content;
    background-color: transparent;

    & > .ab-cta {
      background-color: #a79669;
      width: fit-content;
      @media screen and (max-width: $tablet-breakpoint) {
        position: fixed;
        left: 0;
        right: 0;
        width: 100%;
        bottom: 0;
      }

      & + .ab-cta {
        margin-left: 36px;
        @media screen and (max-width: $tablet-breakpoint) {
          display: none;
        }
      }
    }
  }

  &.reserva {
    @media screen and (max-width: $tablet-breakpoint) {
      height: 72px !important;
    }
    @media screen and (min-width: 1000px) {
      max-width: 220px !important;
      height: 70px !important;
      padding: 0 !important;
      & > a {
        flex: 1;
        align-self: stretch;
        font-size: 15px;
        line-height: 20px;
        padding-right: 25px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
    &:after {
      content: url(/images/calendar.svg);
      position: absolute;
      right: 24px;
      pointer-events: none;
    }
  }

  &.add-to-cart {
    &:after {
      content: url(/images/cart.svg);
      position: absolute;
      right: 24px;
    }

    &.small {
      width: 231px;
    }
  }

  &.anadir {
    width: 130px !important;
    height: 44px;
    @media screen and (max-width: $tablet-breakpoint) {
      width: 100% !important;
    }
    &:after {
      content: url(/images/cart.svg);
      position: absolute;
      right: 15px;
    }
  }
}

.ab-cta-options {
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 24px 0;

  @media screen and (max-width: $tablet-breakpoint) {
    width: 100%;
    // display: none;
  }

  &.small {
    width: fit-content;
  }

  & > span {
    margin-left: 10px;
    font-family: GT Eesti;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #372f2f;
  }

  & > div {
    position: relative;
    & > .title {
      position: absolute;
      opacity: 0;
      transition: all 300ms;
      top: 0;
      pointer-events: none;
      &.show {
        opacity: 1;
        top: -15px;
      }
    }
    & > select {
      cursor: inherit;
      font-family: GT Eesti;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;

      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #372f2f;
      outline: none;
      background: url(/images/select.svg) no-repeat right;
      appearance: none;
      padding-right: 16px;
      padding-left: 5px;
      border-bottom: none;
      padding-bottom: 0;

      & > option {
        font-family: GT Eesti, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;

        letter-spacing: 0.2em;
        text-transform: uppercase;
        &:disabled {
          background-color: #ccc;
        }
      }
    }
  }

  & > .row {
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: $tablet-breakpoint) {
    }

    & > button {
      background-color: #e3decf;
      width: 20px;
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      height: 20px;
      max-height: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > span {
      font-family: GT Eesti;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #372f2f;
      margin: 0 6px;
    }
  }
}

.e-box-cta {
  & > .cta {
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 78px;
    display: none;
    @media screen and (max-width: $tablet-breakpoint) {
      display: flex;
      flex-direction: column;
      align-items: initial;
      margin-bottom: 0;
      margin-top: 0;
    }
    @media screen and (max-width: $tablet-breakpoint) {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 15;
    }

    &.stock-error {
      margin-bottom: 28px;
    }

    & > .ab-cta-options {
      background-color: transparent;
      justify-content: space-between;
      flex: 1;
      margin-right: 27px;
      display: flex;
      @media screen and (max-width: $tablet-breakpoint) {
        background-color: white;
        margin-right: 0;
        justify-content: space-around;
        width: 100%;
      }

      &.small {
        flex: initial;
      }
    }
    & > button {
      @media screen and (max-width: $tablet-breakpoint) {
        width: 100%;
        height: 70px;
      }
    }
  }
}
