.loading {
  display: flex;
  flex: 1;
  align-self: stretch;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  height: 100%;
  width: 100%;
}

.loading-mini {
  height: 20px;
  width: 20px;
  padding-left: 9px;
  padding-right: 9px;
}
