@import '@styles/_variables.scss';
.ab-calendar {
  margin-top: 5px;
  font-family: Silk;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #ffffff;
  position: relative;
  user-select: none;

  &>.calendar {
    position: absolute;
    bottom: 110%;
    background: #f9f6ef;
    width: 280px;
    z-index: 10;

    &.dropdown {
      top: 0%;
      position: relative;
      color: #000 !important;

    }

    &>.title {
      padding: 21px 17px 19px;
      flex-direction: row;

      &>span {
        flex: 1;
        color: #372f2f;
      }

      &>button {
        cursor: pointer;
        transition: all 120ms ease-in-out;
        margin-left: 2px;

        &:hover {
          background-color: #ffffff;
        }
      }
    }

    &>.days-of-week {
      flex-direction: row;

      &>span {
        flex: 1;
        color: #372f2f;
        text-align: center;
        height: 40px;
      }
    }

    &>.days {
      flex-direction: row;
      flex-wrap: wrap;

      &>button,
      &>div {
        box-sizing: border-box;
        display: flex;
        border: 1px solid #fff;
        width: calc(100% / 7);
        color: #372f2f;
        align-items: center;
        justify-content: center;
        height: 40px;
        transition: all 120ms ease-in-out;

        &>span {
          text-align: center;
          height: fit-content;
        }

        &.selected {
          color: #fff;
          background-color: #372f2f;
        }

        &.disabled {
          background-color: #f9f6ef;
        }

        cursor: pointer;

        &:hover {
          background-color: #ffffff;
          color: #372f2f;
        }
      }

      &>div {
        border: 1px solid #f9f6ef;
        cursor: default;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  &>.hide {
    height: 0;
    padding-bottom: 20px;
  }

  &>.color {
    color: #000;
    padding-bottom: 20px;
  }
}