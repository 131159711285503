@import "@styles/_variables.scss";
h1 {
  font-family: GT Eesti;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #a79669;
  margin-bottom: 37px;
}

h2 {
  font-family: Silk;
  font-weight: 300;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -0.03em;
  flex-direction: row;
  flex-wrap: wrap;
  @media screen and (max-width: $tablet-breakpoint) {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -0.04em;
  }

  &.hero-text {
    font-family: Silk;
    font-weight: 300;
    font-size: 100px;
    line-height: 100px;
    text-align: center;
    letter-spacing: -0.03em;

    @media screen and (max-width: $tablet-breakpoint) {
      font-size: 48px;
      line-height: 46px;
      letter-spacing: -0.04em;
    }
  }

  &.title {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.04em;
  }

  &.mobile {
    font-size: 36px;
    line-height: 40px;
  }

  & > span {
    display: inline-block;
  }
}

h3 {
  font-family: Silk;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #372f2f;

  &.gold {
    color: #a79669;
  }

  @media screen and (max-width: $tablet-breakpoint) {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
}

h4 {
  font-family: Silk;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #372f2f;
}

span {
  &.titles {
    font-family: Silk;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.04em;

    @media screen and (max-width: $tablet-breakpoint) {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
    }
  }

  &.title {
    font-family: GT Eesti;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  &.gold {
    color: #a79669;
    font-family: GT Eesti;
  }
}

p {
  font-family: GT Eesti;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;

  & > ul {
    list-style-position: inside;
    list-style-type: disc;
    margin-top: 20px;
    & > li {
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }
}

.inner-list {
  & > ul {
    font-family: GT Eesti;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #8c8181;
    list-style-position: inside;
    list-style-type: disc;
    margin-top: 20px;
    & > li {
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }
}
