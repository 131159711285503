@import '@styles/_variables.scss';
.ab-link {
  font-family: GT Eesti;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #a79669 !important;
  padding: 0 5px 10px;
  border-bottom: 1px solid #a79669;
  width: fit-content;
  cursor: pointer;
  &:hover {
    color: #a79669;
  }
}
