@import "@styles/_variables.scss";
.sub-menu-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 100vh;
  width: 100%;
  max-width: 500px;
  left: -200vw;
  background-color: #f9f6ef;
  transition: left 300ms;

  &.open {
    left: 0;
  }

  & > .row {
    flex-direction: row;
    justify-content: space-between;
    height: 72px;
    padding: 0 30px 0;

    & > a {
      display: flex;
      flex-direction: row;
      align-items: center;
      &:nth-of-type(1) {
        font-family: GT Eesti;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        /* identical to box height, or 160% */

        display: flex;
        align-items: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;

        /* BROWN_ABADÍA */

        color: #372f2f;
      }

      &:nth-of-type(2) {
        font-family: Silk;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height, or 120% */

        text-align: right;
        letter-spacing: -0.02em;

        /* BROWN_ABADÍA */

        color: #372f2f;

        &:before {
          content: url(/images/left.svg);
          margin-top: 4px;
          margin-right: 5px;
        }
      }
    }
  }
}

.submenu-inner {
  flex: 1;
  overflow-y: scroll;
  padding: 0 30px 20px;
  &.hotel {
    margin-top: 13px;
    & > a {
      &:not(.ab-link) {
        font-family: Silk;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 40px;
        letter-spacing: -0.04em;
        color: #372f2f;
        margin-bottom: 21px;
      }

      & > img {
        width: 277px;
        height: 207px;
        margin-top: 10px;
      }
    }

    & > span {
      margin: 10px 0 20px;
      font-family: GT Eesti;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      letter-spacing: 0.05em;

      /* LIGHT_BROWN_ABADÍA */

      color: #8c8181;
    }

    & > div {
      margin-bottom: 21px;
      & > a {
        font-family: Silk;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 40px;
        letter-spacing: -0.04em;
        color: #372f2f;
      }
      & > p {
        font-family: GT Eesti;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #8c8181;
      }
    }
  }
}
