@font-face {
  font-family: "Silk";
  src: url("/fonts/silk/SilkSerif-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Silk";
  src: url("/fonts/silk/SilkSerif-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Silk";
  src: url("/fonts/silk/SilkSerif-Light.ttf") format("truetype"),
    url("/fonts/silk/SilkSerif-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* @font-face {
    font-family: "GT Eesti";
    src: url("GTEesti/GT-Eesti-Text-Medium.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  } */

@font-face {
  font-family: "GT Eesti";
  src: url("/fonts/GTEesti/GTEestiProDisplay-Light.woff") format("woff"),
    url("/fonts/GTEesti/GTEestiProDisplay-Light.woff2") format("woff2"),
    url("/fonts/GTEesti/GTEestiProDisplay-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "GT Eesti";
  src: url("/fonts/GTEesti/GT-Eesti-Display-Regular.woff") format("woff"),
    url("/fonts/GTEesti/GT-Eesti-Display-Regular.woff2") format("woff2"),
    url("/fonts/GTEesti/GT-Eesti-Display-Regular.ttf") format("truetype"),
    url("/fonts/GTEesti/GT-Eesti-Display-Regular.otf") format("opentype");
}

html {
  scroll-behavior: smooth !important;
}

#__next {
  padding-top: 100vh;
  position: relative;

  &>div {
    z-index: 1;
  }

  &>footer {
    z-index: 1;
  }
}

select {
  cursor: inherit;
  font-family: GT Eesti;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  letter-spacing: 0.05em;
  color: #372f2f;
  outline: none;
  background: url(/images/select.svg) no-repeat top right;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 16px;
  padding-bottom: 22px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.2);

  &.error {
    border-bottom: 1px solid red;
  }
}

.gold {
  color: #a79669 !important;
}

span {
  &.error {
    color: red;
    font-family: GT Eesti;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
  }
}

.react-date-picker {
  position: absolute !important;
  top: 0;
  right: 0;

  .react-date-picker__wrapper {
    .react-date-picker__inputGroup {
      display: none;
    }

    .react-date-picker__clear-button {
      display: none;
    }

    .react-date-picker__calendar-button {
      padding: 0;
    }
  }
}