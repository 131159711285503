@import '@styles/_variables.scss';

.popup-cierre-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9999;
  transform: translateX(300px);
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;

  // @media (max-width: 600px) {
  //   overflow-y: auto;
  // }

  &.show {
    opacity: 1;
    pointer-events: initial;
    transform: translateX(0);

  }
}

.popup-cierre-image-container {
  display: flex;
  align-self: center;
  max-width: 200px;
  margin-bottom: 15px;
}

.cierre-title {
  font-size: 22px;
  font-family: GT Eesti;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 8px;
  }
}

.popup-cierre-close {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-tap-highlight-color: transparent;
  transform: rotate(-45deg);
  user-select: none;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 5px;
  font-size: 42px;
  font-weight: 300;
  background-color: transparent;
}

.popup-cierre-content {
  text-align: center;
  animation: all .2s forwards;
  transform: translate3d(0, -18px, 0);
  background-color: #f9f6ef;
  width: 246px;
  max-width: 100%;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: 140px;
  right: 15px;
  padding: 20px;
  z-index: 9999;
  min-height: 374px;
  max-height: 70%;
  box-shadow: 0px 10px 10px 0px rgb(0 0 0 / 25%);

  @media (max-width: 600px) {
    top: 90px;
    right: 0px;
    left: 0px;
    padding: 2px;
  }
}

.popup-cierre-form {
  padding: 15px;
  flex-direction: column;
  display: flex;
  justify-content: center;

  p {
    font-size: 12px;
    line-height: 20px;
  }

  a {
    color: #A79669;
  }

  &>.ab-form-button {
    align-self: center;

    &>a {
      align-self: stretch;
      font-size: 12px;
      height: 70px;
      line-height: 70px;

    }
  }

  @media (max-width: 600px) {
    padding: 14px;
    padding-top: 40px;
  }
}