@import '@styles/_variables.scss';
.book-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9999;
  transition: all 500ms ease-in-out;
  transform: translateX(300px);
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;

  // @media (max-width: 600px) {
  //   overflow-y: auto;
  // }

  &.show {
    opacity: 1;
    pointer-events: initial;
    transform: translateX(0);

  }
}

.book-close {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-tap-highlight-color: transparent;
  transform: rotate(-45deg);
  user-select: none;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 5px;
  font-size: 42px;
  font-weight: 300;
  background-color: transparent;
}

.book-content {
  animation: all .2s forwards;
  transform: translate3d(0, -18px, 0);
  background-color: #f9f6ef;
  width: 300px;
  max-width: 100%;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: 140px;
  right: 15px;
  padding: 36px;
  z-index: 9999;
  min-height: 300px;
  max-height: 70%;
  box-shadow: 0px 10px 10px 0px rgb(0 0 0 / 25%);

  @media (max-width: 600px) {
    top: 90px;
    right: 0px;
    left: 0px;
    padding: 15px;
    width: auto;
    height: 100%;
    max-height: calc(100% - 70px);

  }
}

.book-form {
  padding: 15px;
  flex-direction: column;
  display: flex;
  justify-content: center;

  &>.ab-form-button {
    align-self: center;

    &>a {
      align-self: stretch;
      font-size: 12px;
      height: 70px;
      line-height: 70px;
    }
  }

  @media (max-width: 600px) {
    padding: 15px;
    padding-top: 45px;

  }
  .promo-container{
    @media(max-width:600px){
      min-height: 48px;
    }
    .promo{
      a{
        text-decoration: underline;
      }
    }
  }
}