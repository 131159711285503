@import '@styles/_variables.scss';
.ab-cookies {
  background-color: #f9f6ef;
  flex-direction: row;
  padding: 45px 111px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  transition: all 500ms;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 5 !important;
  @media screen and (max-width: 1024px) {
    padding: 45px 62px;
  }
  @media screen and (max-width: $tablet-breakpoint) {
    padding: 61px 30px 30px;
    flex-direction: column;
  }

  &.hide {
    bottom: -1800px;
  }

  &.show {
    bottom: 0;
  }
  & > div {
    &:nth-of-type(2) {
      flex: 1;
      align-items: flex-end;
      @media screen and (max-width: $tablet-breakpoint) {
        align-items: center;
      }
    }
    & > p {
      width: 100%;
      max-width: 693px;
      margin-top: 20px;
    }
    & > button {
      @extend %button;
      max-width: 275px;
      min-width: 150px;
      width: 100%;
      @media screen and (max-width: $tablet-breakpoint) {
        max-width: initial;
      }
    }
  }
}
